<template>
<div>
  <b-container fluid>
    <b-row class="row text-white bg-info">
      <b-col align-self="center" class=" cols text-center" align-v="center">
        <h1 justify="center" align="center" class="kstyle title justify-content-md-center">Initiatives - Reporting Generation</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container>
          <b-row class="my-2">
            <b-col>
              <h2>Risk disclaimer</h2>
              <div class="mb-3">
                <strong>This data is for KPMG internal use only. Engagement Leaders are solely responsible for data provided to clients and they
                need to perform appropriate review and quality control before providing any data to clients.</strong>
              </div>
            </b-col>
          </b-row>
          <b-row class="my-2" v-if="showReportingForm">
            <b-col>
              <h2>What is the report for?</h2>
              <b-form @submit="onSubmit">
                <b-form-group id="select-function-group" label="Function:" label-for="input-function">
                  <b-form-select id="select-function" v-model="form.function" :options="['audit', 'consulting', 'deals', 'tax']"></b-form-select>
                </b-form-group>
                <b-form-group id="select-reason-group" label="Reason:" label-for="input-reason">
                  <b-form-select id="select-reason" v-model="form.reason" :options="['sales support', 'engagement support', 'research', 'data admin', 'personal', 'other']"></b-form-select>
                </b-form-group>
                <b-form-group id="input-reasonother-group" label="Other reason:" label-for="input-reasonother" v-if="form.reason === 'other'">
                  <b-form-input id="input-reasonother" v-model="form.reasonother" type="text" placeholder="other reason"></b-form-input>
                </b-form-group>
                <b-form-group id="input-sales-group" label="Opportunity size in USD:" label-for="input-size" v-if="form.reason === 'sales support' || form.reason === 'engagement support'">
                  <b-form-input id="input-size" v-model="form.size" type="number" placeholder="750000"></b-form-input>
                </b-form-group>
                <b-form-group id="select-industry-group" label="Client sector:" label-for="input-sector" v-if="form.reason === 'sales support' || form.reason === 'engagement support'">
                  <b-form-select id="input-sector" v-model="form.sector" :options="sectorOptions"></b-form-select>
                </b-form-group>
                <b-form-group id="select-industrygroup-group" label="Client industry group:" label-for="input-industrygroup" v-if="industryGroupOptions">
                  <b-form-select id="input-industrygroup" v-model="form.industrygroup" :options="industryGroupOptions"></b-form-select>
                </b-form-group>
                <b-form-group id="select-industry-group" label="Client industry:" label-for="input-industry" v-if="industryOptions">
                  <b-form-select id="input-industry" v-model="form.industry" :options="industryOptions"></b-form-select>
                </b-form-group>
                <b-form-group id="select-subindustry-group" label="Client sub-industry:" label-for="input-subindustry" v-if="subIndustryOptions">
                  <b-form-select id="input-subindustry" v-model="form.subindustry" :options="subIndustryOptions"></b-form-select>
                </b-form-group>
                <div>{{validate.message}}</div>
                <b-button v-if="validate.valid" type="submit" variant="primary">Submit</b-button>
              </b-form>
            </b-col>
          </b-row>
          <b-row class="my-2" v-if="showControls">
            <b-col class="text-center">
              <b-form inline class="mb-4">
                <b-button variant="outline-primary" class="mb-2 mr-2" :to="{ name: 'ImpactInitiatives' }">Initiatives</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-4" @click="excel">download all to Excel</b-button>
                <!-- <b-button variant="outline-primary" class="mb-2 mr-2" v-b-toggle.sidebar-1>settings</b-button> -->
                Word report type:
                <b-form-select class="ml-2 mr-2" v-model="reportSelected" :options="reportOptions"></b-form-select>
              </b-form>
            </b-col>
          </b-row>
          <b-row class="my-2" v-if="reportSelected === 'grouped'">
            <b-col class="text-center">
              <b-form inline class="mb-4">
                Group by:
                <b-form-select class="ml-2 mr-2" v-model="level1" :options="level1options"></b-form-select>
                <b-form-select class="mr-2" v-model="level2" :options="level2options"></b-form-select>
                <b-form-select v-if="level2" class="mr-2" v-model="level3" :options="level3options"></b-form-select>
              </b-form>
            </b-col>
          </b-row>
          <b-row class="my-2" v-if="reportSelected === 'full'">
            <b-col>
              <div>Publications</div>
              <b-form inline class="mb-3">
                start: <b-form-input class="ml-1 mr-3" type="date" v-model="config.dates.publications.start"></b-form-input>
                end: <b-form-input class="ml-1 mr-3" type="date" v-model="config.dates.publications.end"></b-form-input>
                <b-button variant="outline-primary" @click="config.dates.publications.start = null; config.dates.publications.end = null;">no publications</b-button>
              </b-form>
              <div>Insights</div>
              <b-form inline class="mb-3">
                start: <b-form-input class="ml-1 mr-3" type="date" v-model="config.dates.articles.start"></b-form-input>
                end: <b-form-input class="ml-1 mr-3" type="date" v-model="config.dates.articles.end"></b-form-input>
                <b-button variant="outline-primary" @click="config.dates.articles.start = null; config.dates.articles.end = null;">no articles</b-button>
              </b-form>
              <div>Events</div>
              <b-form inline class="mb-3">
                start: <b-form-input class="ml-1 mr-3" type="date" v-model="config.dates.events.start"></b-form-input>
                end: <b-form-input class="ml-1 mr-3" type="date" v-model="config.dates.events.end"></b-form-input>
                <b-button variant="outline-primary" @click="config.dates.events.start = null; config.dates.events.end = null;">no events</b-button>
              </b-form>
              <div>Report variant</div>
              <b-form inline class="mb-3">
                <b-form-select class="mr-2" v-model="reportFullSelected" :options="reportFullOptions"></b-form-select>
              </b-form>
              <b-button variant="outline-primary" class="mb-2" @click="fullReport">get data and generate report</b-button>
            </b-col>
          </b-row>
          <b-row class="my-2" v-if="reportReady">
            <b-col class="text-center">
              <b-form inline class="mb-2">
                <b-button class="mr-2" variant="outline-primary" v-b-toggle.sidebar-1>report options</b-button>
                <b-button variant="outline-primary" @click="generateReport">download Word report</b-button>
              </b-form>
            </b-col>
          </b-row>
          <b-row v-if="loading">
            <b-col>
              <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
              loaded: {{counter}} records
            </b-col>
          </b-row>
          <b-row v-if="reportReady && !loading">
            <b-col>
              <h2>Report layout preview</h2>
              <div v-if="initiativesFull.length > 0 && reportSelected === 'full' && reportFullSelected === 'tabular'" id="exportElement">
                <div v-if="articles.length > 0 && options.insights.value">
                  <h1 class="section">Insights</h1>
                  <table>
                    <tr>
                      <td>Initiative</td>
                      <td>Insight</td>
                      <td>Details</td>
                      <td>Timelines</td>
                    </tr>
                    <tr v-for="row in articles" :key="'article'+row.id">
                      <td>{{row.initiaitiveName}}</td>
                      <td>{{row.title}}</td>
                      <td>{{row.body}}</td>
                      <td>
                        <div v-if="row.events.length > 0">
                          <div v-for="event in row.events" :key="'event' + event.id">
                            {{moment(event.date).format('YYYY-MM-DD')}}: {{event.name}}<br />
                            {{event.comments}}<br /><br />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div v-if="initiativesFull.length > 0 && reportSelected === 'full' && reportFullSelected === 'split'" id="exportElement">
                <h1 class="section">Recent updates</h1>
                <div class="mb-3" v-for="item in initiativesFull" :key="'initiative-one-' + item.id">
                  <h2>{{item.name}}</h2>
                  <div v-if="item.events.length > 0 && options.events.value">
                    <h3 class="mt-5">Events</h3>
                    <div v-for="row in item.events" :key="'event'+row.id">
                      <h4>{{moment(row.date).format('YYYY-MM-DD')}} - {{row.name}}</h4>
                      <p>{{row.comments}}</p>
                    </div>
                  </div>
                  <div v-if="item.articles.length > 0 && options.insights.value">
                    <h3 class="mt-5">INSIGHTS</h3>
                    <div v-for="row in item.articles" :key="'article'+row.id" class="mb-2">
                      <h3>{{row.title}}</h3>
                      <div><small>{{moment(row.approved_at).format('YYYY-MM-DD')}}</small></div>
                      <div v-html="row.body"></div>
                    </div>
                  </div>
                  <div v-if="item.publications.length > 0 && options.publications.value">
                    <h3 class="mt-5">PUBLICATIONS</h3>
                    <div v-for="row in item.publications" :key="'publication'+row.id" class="mb-2">
                      <h4>{{row.name}}</h4>
                      <div><small>{{moment(row.created_at).format('YYYY-MM-DD')}}: {{row.publisher}}</small></div>
                      <div v-html="row.body"></div>
                      <div><a :href="row.link">external link</a></div>
                    </div>
                  </div>
                </div>
                <h1 class="section">Background</h1>
                <div class="mb-3" v-for="item in initiativesFull" :key="'initiative-' + item.id">
                  <h2>{{item.name}}</h2>
                  <div v-if="options.country.value"><p>{{item.country}} | {{item.impactstatus.name}}</p></div>
                  <p v-if="options.headline.value">{{item.headline}}</p>
                  <div v-if="options.descriptionInitiative.value" v-html="item.description"></div>
                  <div v-if="options.workflow.value">
                    <h4>Workflow</h4>
                    <div>status: <strong>{{workflow.find(x => x.id === item.workflow_id).name}}</strong></div>
                    <div v-if="item.workflow_created_by"><small>{{item.workflow_created_by}} at {{moment(item.workflow_created_at).format('YYYY-MM-DD HH:mm')}}</small></div>
                  </div>
                  <div v-if="item.impactexperts.length > 0 && options.experts.value">
                    <h4 class="mt-5">Experts</h4>
                    <div v-for="expert in item.impactexperts" :key="'expert'+expert.id">
                      {{expert.name}}
                    </div>
                  </div>
                  <div v-if="(item.issued || item.effective || item.first_reporting)  && options.dates.value">
                    <h4 class="mt-5">Dates</h4>
                    <div v-if="item.issued">Issued: {{item.issued}}</div>
                    <div v-if="item.effective">Effective: {{item.effective}}</div>
                    <div v-if="item.first_reporting">First reporting: {{item.first_reporting}}</div>
                  </div>
                  <div v-if="item.reportingfrequency  && options.reportingFrequency.value">
                    <div v-if="item.reportingfrequency.id != 1">
                      <h4 class="mt-5">Reporting frequency</h4>
                      <div>{{item.reportingfrequency.name}}</div>
                    </div>
                  </div>
                  <div v-if="item.report_type  && options.reportType.value">
                    <h4 class="mt-5">Report type</h4>
                    <div>{{item.report_type}}</div>
                  </div>
                  <div v-if="item.reportingframeworks.length > 0  && options.reportingFrameworks.value">
                    <h4 class="mt-5">Reporting frameworks</h4>
                    <div v-for="row in item.reportingframeworks" :key="'entity'+row.id" class="mb-2">
                      {{row.name}}
                    </div>
                  </div>
                  <div v-if="item.assurance && options.assurance.value">
                    <div v-if="item.assurance.id != 1">
                      <h4 class="mt-5">Assurance</h4>
                      <div>{{item.assurance.name}}</div>
                    </div>
                  </div>
                  <div v-if="(item.mandatory || item.optional || item.comply_explain) && options.reportingObligation.value">
                    <h4 class="mt-5">Reporting obligation</h4>
                    <div>
                      <span v-if="item.mandatory">mandatory | </span>
                      <span v-if="item.optional">optional | </span>
                      <span v-if="item.comply_explain">comply or explain</span>
                    </div>
                  </div>
                  <div v-if="item.impacttopics.length > 0 && options.topics.value">
                    <h4 class="mt-5">Topics</h4>
                    <div v-for="topic in item.impacttopics" :key="'topic'+topic.id">
                      {{topic.name}}
                    </div>
                  </div>
                  <div v-if="item.regulators.length > 0 && options.regulators.value">
                    <h4 class="mt-5">Regulators</h4>
                    <div v-for="row in item.regulators" :key="'entity'+row.id" class="mb-2">
                      <strong>{{row.name}}</strong><br />{{row.comments}}
                    </div>
                  </div>
                  <div v-if="item.entities.length > 0 && options.entities.value">
                    <h4 class="mt-5">Entities</h4>
                    <div v-for="row in item.entities" :key="'entity'+row.id" class="mb-2">
                      {{row.name}}
                    </div>
                  </div>
                  <div v-if="item.impactinitiativeslinks.length > 0 && options.externalLinks.value">
                    <h4 class="mt-5">External links</h4>
                    <div v-for="link in item.impactinitiativeslinks" :key="'link'+link.id" class="mb-3">
                      <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="initiativesFull.length > 0 && reportSelected === 'full' && reportFullSelected === 'together'" id="exportElement">
                <div class="mb-3" v-for="item in initiativesFull" :key="'initiative-' + item.id">
                  <h1 class="section">{{item.name}}</h1>
                  <div v-if="options.country.value"><p>{{item.country}} | {{item.impactstatus.name}}</p></div>
                  <p v-if="options.headline.value">{{item.headline}}</p>
                  <div v-if="options.descriptionInitiative.value" v-html="item.description"></div>
                  <div v-if="options.workflow.value">
                    <h4>Workflow</h4>
                    <div>status: <strong>{{workflow.find(x => x.id === item.workflow_id).name}}</strong></div>
                    <div v-if="item.workflow_created_by"><small>{{item.workflow_created_by}} at {{moment(item.workflow_created_at).format('YYYY-MM-DD HH:mm')}}</small></div>
                  </div>
                  <div v-if="item.impactexperts.length > 0 && options.experts.value">
                    <h4 class="mt-5">Experts</h4>
                    <div v-for="expert in item.impactexperts" :key="'expert'+expert.id">
                      {{expert.name}}
                    </div>
                  </div>
                  <div v-if="(item.issued || item.effective || item.first_reporting) && options.dates.value">
                    <h4 class="mt-5">Dates</h4>
                    <div v-if="item.issued">Issued: {{item.issued}}</div>
                    <div v-if="item.effective">Effective: {{item.effective}}</div>
                    <div v-if="item.first_reporting">First reporting: {{item.first_reporting}}</div>
                  </div>
                  <div v-if="item.reportingfrequency && options.reportingFrequency.value">
                    <div v-if="item.reportingfrequency.id != 1">
                      <h4 class="mt-5">Reporting frequency</h4>
                      <div>{{item.reportingfrequency.name}}</div>
                    </div>
                  </div>
                  <div v-if="item.report_type && options.reportType.value">
                    <h4 class="mt-5">Report type</h4>
                    <div>{{item.report_type}}</div>
                  </div>
                  <div v-if="item.reportingframeworks.length > 0 && options.reportingFrameworks.value">
                    <h4 class="mt-5">Reporting frameworks</h4>
                    <div v-for="row in item.reportingframeworks" :key="'entity'+row.id" class="mb-2">
                      {{row.name}}
                    </div>
                  </div>
                  <div v-if="item.assurance && options.assurance.value">
                    <div v-if="item.assurance.id != 1">
                      <h4 class="mt-5">Assurance</h4>
                      <div>{{item.assurance.name}}</div>
                    </div>
                  </div>
                  <div v-if="(item.mandatory || item.optional || item.comply_explain) && options.reportingObligation.value">
                    <h4 class="mt-5">Reporting obligation</h4>
                    <div>
                      <span v-if="item.mandatory">mandatory | </span>
                      <span v-if="item.optional">optional | </span>
                      <span v-if="item.comply_explain">comply or explain</span>
                    </div>
                  </div>
                  <div v-if="item.impacttopics.length > 0 && options.topics.value">
                    <h4 class="mt-5">Topics</h4>
                    <div v-for="topic in item.impacttopics" :key="'topic'+topic.id">
                      {{topic.name}}
                    </div>
                  </div>
                  <div v-if="item.regulators.length > 0 && options.regulators.value">
                    <h4 class="mt-5">Regulators</h4>
                    <div v-for="row in item.regulators" :key="'entity'+row.id" class="mb-2">
                      <strong>{{row.name}}</strong><br />{{row.comments}}
                    </div>
                  </div>
                  <div v-if="item.entities.length > 0 && options.entities.value">
                    <h4 class="mt-5">Entities</h4>
                    <div v-for="row in item.entities" :key="'entity'+row.id" class="mb-2">
                      {{row.name}}
                    </div>
                  </div>
                  <div v-if="item.impactinitiativeslinks.length > 0 && options.externalLinks.value">
                    <h4 class="mt-5">External links</h4>
                    <div v-for="link in item.impactinitiativeslinks" :key="'link'+link.id" class="mb-3">
                      <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
                    </div>
                  </div>
                  <div v-if="item.events.length > 0 && options.events.value">
                    <h4 class="mt-5">Events</h4>
                    <div v-for="row in item.events" :key="'event'+row.id" class="mb-2">
                      <strong>{{moment(row.date).format('YYYY-MM-DD')}} - {{row.name}}</strong>
                      <p>{{row.comments}}</p>
                    </div>
                  </div>
                  <div v-if="item.articles.length > 0 && options.insights.value">
                    <h4 class="mt-5">INSIGHTS</h4>
                    <div v-for="row in item.articles" :key="'article'+row.id" class="mb-2">
                      <h2>{{row.title}}</h2>
                      <div><small>{{moment(row.approved_at).format('YYYY-MM-DD')}}</small></div>
                      <div v-html="row.body"></div>
                    </div>
                  </div>
                  <div v-if="item.publications.length > 0 && options.publications.value">
                    <h4 class="mt-5">PUBLICATIONS</h4>
                    <div v-for="row in item.publications" :key="'publication'+row.id" class="mb-2">
                      <h2>{{row.name}}</h2>
                      <div><small>{{moment(row.created_at).format('YYYY-MM-DD')}}: {{row.publisher}}</small></div>
                      <div v-html="row.body"></div>
                      <div><a :href="row.link">external link</a></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="initiatives.length > 0 && reportSelected === 'simple'" id="exportElement">
                <div class="mb-3" v-for="item in initiatives" :key="'initiative-' + item.id">
                  <initiative-report
                    :item="item"
                    :options="options"
                    heading="h3"
                  ></initiative-report>
                </div>
              </div>
              <div v-if="reportSelected === 'grouped'" id="exportElement">
                <initiative-recursive
                  :rows="grouped"
                  :options="options"
                ></initiative-recursive>
              </div>
              <div v-if="initiativesObligations.length > 0 && reportSelected === 'obligations'" id="exportElement">
                <div class="mb-3" v-for="item in initiativesObligations" :key="'initiative-' + item.id">
                  <h1>{{item.name}}</h1>
                  <div v-if="options.country.value"><p>{{item.country}} | {{item.impactstatus.name}}</p></div>
                  <p v-if="options.headline.value">{{item.headline}}</p>
                  <div v-if="options.descriptionInitiative.value" v-html="item.description"></div>
                  <div v-if="options.workflow.value">
                    <h4>Workflow</h4>
                    <div>status: <strong>{{workflow.find(x => x.id === item.workflow_id).name}}</strong></div>
                    <div v-if="item.workflow_created_by"><small>{{item.workflow_created_by}} at {{moment(item.workflow_created_at).format('YYYY-MM-DD HH:mm')}}</small></div>
                  </div>
                  <div v-if="item.impactexperts.length > 0 && options.experts.value">
                    <h4 class="mt-5">Experts</h4>
                    <div v-for="expert in item.impactexperts" :key="'expert'+expert.id">
                      {{expert.name}}
                    </div>
                  </div>
                  <div v-if="(item.issued || item.effective || item.first_reporting) && options.dates.value">
                    <h4 class="mt-5">Dates</h4>
                    <div v-if="item.issued">Issued: {{item.issued}}</div>
                    <div v-if="item.effective">Effective: {{item.effective}}</div>
                    <div v-if="item.first_reporting">First reporting: {{item.first_reporting}}</div>
                  </div>
                  <div v-if="item.reportingfrequency && options.reportingFrequency.value">
                    <div v-if="item.reportingfrequency.id != 1">
                      <h4 class="mt-5">Reporting frequency</h4>
                      <div>{{item.reportingfrequency.name}}</div>
                    </div>
                  </div>
                  <div v-if="item.report_type && options.reportType.value">
                    <h4 class="mt-5">Report type</h4>
                    <div>{{item.report_type}}</div>
                  </div>
                  <div v-if="item.reportingframeworks.length > 0 && options.reportingFrameworks.value">
                    <h4 class="mt-5">Reporting frameworks</h4>
                    <div v-for="row in item.reportingframeworks" :key="'entity'+row.id" class="mb-2">
                      {{row.name}}
                    </div>
                  </div>
                  <div v-if="item.assurance && options.assurance.value">
                    <div v-if="item.assurance.id != 1">
                      <h4 class="mt-5">Assurance</h4>
                      <div>{{item.assurance.name}}</div>
                    </div>
                  </div>
                  <div v-if="(item.mandatory || item.optional || item.comply_explain) && options.reportingObligation.value">
                    <h4 class="mt-5">Reporting obligation</h4>
                    <div>
                      <span v-if="item.mandatory">mandatory | </span>
                      <span v-if="item.optional">optional | </span>
                      <span v-if="item.comply_explain">comply or explain</span>
                    </div>
                  </div>
                  <div v-if="item.impacttopics.length > 0 && options.topics.value">
                    <h4 class="mt-5">Topics</h4>
                    <div v-for="topic in item.impacttopics" :key="'topic'+topic.id">
                      {{topic.name}}
                    </div>
                  </div>
                  <div v-if="item.regulators.length > 0 && options.regulators.value">
                    <h4 class="mt-5">Regulators</h4>
                    <div v-for="row in item.regulators" :key="'entity'+row.id" class="mb-2">
                      <strong>{{row.name}}</strong><br />{{row.comments}}
                    </div>
                  </div>
                  <div v-if="item.entities.length > 0 && options.entities.value">
                    <h4 class="mt-5">Entities</h4>
                    <div v-for="row in item.entities" :key="'entity'+row.id" class="mb-2">
                      {{row.name}}
                    </div>
                  </div>
                  <div v-if="item.impactinitiativeslinks.length > 0 && options.externalLinks.value">
                    <h4 class="mt-5">External links</h4>
                    <div v-for="link in item.impactinitiativeslinks" :key="'link'+link.id" class="mb-3">
                      <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
                    </div>
                  </div>
                  <div v-if="item.obligations.length > 0">
                    <h3 class="mt-5">Obligations</h3>
                    <div class="my-4" v-for="obligation in item.obligations"  :key="'obligation-'+ obligation.id">
                      <h5><strong v-if="obligation.obligationtype">{{obligation.obligationtype.name}}: </strong>{{obligation.name}}</h5>
                      <div><strong>description</strong></div>
                      <div class="mb-2"><new-lines :content="obligation.description"></new-lines></div>
                      <div><strong>context / entity scope</strong></div>
                      <div class="mb-2"><new-lines :content="obligation.context"></new-lines></div>
                      <div v-if="obligation.dates">
                        <div v-if="obligation.dates.length > 0">
                          <div v-for="date in obligation.dates" :key="'date' + date.id">
                            <strong>{{date.date}}</strong> - {{date.name}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-sidebar id="sidebar-1" title="Report settings" shadow>
    <div class="px-3 py-1" v-for="(row, index) in options" :key="'options-' + index">
      <b-form-checkbox v-model="row.value" :value="true" :unchecked-value="false">{{row.text}}</b-form-checkbox>
    </div>
  </b-sidebar>
</div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import industries from '@/libs/industries.js'
import moment from 'moment'
import NewLines from '@/components/NewLines.vue'
import striptags from 'striptags'
import workflow from '@/workflows/initiative'
import zipcelx from 'zipcelx'

import InitiativeReport from '@/components/InitiativeReport'
import InitiativeRecursive from '@/components/InitiativeReportRecursive'

const structure = {
  name: 'initiatives',
  schema: [
    {
      id: 'country',
      dictionary: 'countries',
      displayName: 'country',
      property: 'country',
      type: 'singleField'
    },
    {
      id: 'assurance',
      dictionary: 'assurance',
      displayName: 'assurance',
      property: 'assurance',
      type: 'standardObject',
      prefix: 'Assurance: '
    },
    {
      id: 'dataset',
      dictionary: 'datasets',
      displayName: 'data set',
      property: 'faqproject',
      type: 'standardObject',
      prefix: ''
    },
    {
      id: 'entity',
      dictionary: 'entities',
      displayName: 'entities',
      property: 'entities',
      type: 'standardArray'
    },
    {
      id: 'expert',
      dictionary: 'experts',
      displayName: 'experts',
      property: 'impactexperts',
      type: 'standardArray'
    },
    {
      id: 'impactstatus',
      dictionary: 'impactstatus',
      displayName: 'implementation status',
      property: 'impactstatus',
      type: 'standardObject',
      prefix: 'Implementation: '
    },
    {
      id: 'impacttopic',
      dictionary: 'impacttopics',
      displayName: 'impact topics',
      property: 'impacttopics',
      type: 'standardArray'
    },
    {
      id: 'mandatory',
      dictionary: 'mandatory',
      displayName: 'mandatory',
      type: 'multipleBooleanFields',
      properties: [
        {
          property: 'mandatory',
          displayName: 'Mandatory'
        },
        {
          property: 'optional',
          displayName: 'Optional'
        },
        {
          property: 'comply_explain',
          displayName: 'Comply or explain'
        }
      ]
    },
    {
      id: 'regulator',
      dictionary: 'regulators',
      displayName: 'regulators',
      property: 'regulators',
      type: 'standardArray'
    },
    {
      id: 'reportingframework',
      dictionary: 'reportingframeworks',
      displayName: 'reporting frameworks',
      property: 'reportingframeworks',
      type: 'standardArray'
    },
    {
      id: 'reportingfrequency',
      dictionary: 'reportingfrequency',
      displayName: 'reporting frequency',
      property: 'reportingfrequency',
      type: 'standardObject',
      prefix: 'reporting frequency: '
    }
  ]
}

const extractors = {
  singleField: function (field, dictionary, data) {
    const results = []
    _.each(dictionary, term => {
      const row = {
        term: term,
        data: _.filter(data, x => {
          return x[field.property] === term
        })
      }
      results.push(row)
    })
    return results
  },
  standardArray: function (field, dictionary, data) {
    const results = []
    _.each(dictionary, term => {
      const row = {
        term: term,
        data: _.filter(data, x => {
          return _.find(x[field.property], y => y.name === term)
        })
      }
      if (row.data.length > 0) {
        results.push(row)
      }
    })
    const otherRows = {
      term: 'Not defined',
      data: _.filter(data, x => {
        return x[field.property].length === 0
      })
    }
    if (otherRows.data.length > 0) {
      results.push(otherRows)
    }
    return results
  },
  standardObject: function (field, dictionary, data) {
    const results = []
    _.each(dictionary, term => {
      const row = {
        term: term,
        data: _.filter(data, x => {
          if (x[field.property] !== null) {
            return field.prefix + x[field.property].name === term
          } else {
            return field.prefix + 'not defined' === term
          }
        })
      }
      results.push(row)
    })
    return results
  },
  multipleBooleanFields: function (field, dictionary, data) {
    const results = []
    _.each(dictionary, term => {
      const row = {
        term: term.displayName,
        data: _.filter(data, x => x[term.property])
      }
      if (row.data.length > 0) {
        results.push(row)
      }
    })
    const otherRows = {
      term: 'Not defined',
      data: []
    }
    _.each(data, row => {
      let belongs = false
      _.each(dictionary, term => {
        if (row[term.property]) {
          belongs = true
        }
      })
      if (!belongs) {
        otherRows.data.push(row)
      }
    })
    if (otherRows.data.length > 0) {
      results.push(otherRows)
    }
    return results
  }
}

const dictionaryMakers = {
  singleField: function (data, field) {
    let options = data.map(row => {
      if (row[field.property]) {
        return row[field.property]
      } else {
        return 'no ' + field.displayName
      }
    })
    options = _.uniq(options)
    options.sort()
    return options
  },
  standardArray: function (data, field) {
    let options = []
    _.each(data, x => {
      _.each(x[field.property], y => {
        options.push(y.name)
      })
    })
    options = _.uniq(options)
    options.sort()
    return options
  },
  standardObject: function (data, field) {
    let options = data.map(row => {
      if (row[field.property] !== null) {
        return field.prefix + row[field.property].name
      } else {
        return field.prefix + 'not defined'
      }
    })
    options = _.uniq(options)
    options.sort()
    return options
  },
  multipleBooleanFields: function (data, field) {
    return field.properties
  }
}

export default {
  name: 'InitiativeReportingMain',
  components: {
    InitiativeRecursive,
    InitiativeReport,
    NewLines
  },
  computed: {
    assuranceSelected: {
      get () {
        return this.$store.state.assuranceSelected
      }
    },
    countrySelected: {
      get () {
        return this.$store.state.countrySelected
      }
    },
    datasetSelected: {
      get () {
        return this.$store.state.datasetSelected
      }
    },
    entitySelected: {
      get () {
        return this.$store.state.entitySelected
      }
    },
    expertsSelected: {
      get () {
        return this.$store.state.expertsSelected
      }
    },
    mandatorySelected: {
      get () {
        return this.$store.state.mandatorySelected
      }
    },
    reportingfrequencySelected: {
      get () {
        return this.$store.state.reportingfrequencySelected
      }
    },
    regulatorSelected: {
      get () {
        return this.$store.state.regulatorSelected
      }
    },
    reportingframeworkSelected: {
      get () {
        return this.$store.state.reportingframeworkSelected
      }
    },
    statusSelected: {
      get () {
        return this.$store.state.statusSelected
      }
    },
    topicSelected: {
      get () {
        return this.$store.state.topicSelected
      }
    },
    workflowSelected: {
      get () {
        return this.$store.state.initiativeWorkflowSelected
      }
    },
    dictionaries: function () {
      const allData = this[structure.name]
      const result = []
      if (allData.length > 0) {
        _.each(structure.schema, field => {
          const options = dictionaryMakers[field.type](allData, field)
          result[field.dictionary] = options
        })
      }
      return result
    },
    articles: function () {
      const articles = []
      this.initiativesFull.forEach(initiative => {
        const iname = initiative.name
        initiative.articles.forEach(article => {
          article.initiaitiveName = iname
          const bodyOriginal = article.body
          const chunks = bodyOriginal.split('</p>')
          const body = striptags(chunks[0] + '</p>')
          article.body = body
          articles.push(article)
        })
      })
      _.orderBy(articles, ['initiativeName'])
      console.log(articles)
      return articles
    },
    initiatives: {
      get () {
        return this.$store.state.showInitiatives
      },
      set (payload) {
        this.$store.commit('setShowInitiatives', payload)
      }
    },
    grouped: function () {
      const level1 = this.level1
      const level2 = this.level2
      const level3 = this.level3
      const level1field = structure.schema.find(x => x.id === level1)
      const results = extractors[level1field.type](level1field, this.dictionaries[level1field.dictionary], this[structure.name])

      if (level2) {
        const level2field = structure.schema.find(x => x.id === level2)
        _.each(results, set => {
          set.data = extractors[level2field.type](level2field, this.dictionaries[level2field.dictionary], set.data)
          if (level3) {
            const level3field = structure.schema.find(x => x.id === level3)
            _.each(set.data, set3 => {
              set3.data = extractors[level3field.type](level3field, this.dictionaries[level3field.dictionary], set3.data)
            })
          }
        })
      }
      return results
    },
    level1options: function () {
      return structure.schema.map(x => x.id)
    },
    level2options: function () {
      let response = [null].concat(structure.schema.map(x => x.id))
      response = _.pull(response, this.level1)
      return response
    },
    level3options: function () {
      let response = [null].concat(structure.schema.map(x => x.id))
      response = _.pull(response, this.level1)
      response = _.pull(response, this.level2)
      return response
    },
    sectorOptions: function () {
      let results = industries.map(x => x.sector)
      results = _.uniq(results)
      return results
    },
    industryGroupOptions: function () {
      if (!this.form.sector) {
        return null
      }
      const industryGroups = industries.filter(x => x.sector === this.form.sector)
      if (!Array.isArray(industryGroups)) {
        return null
      }
      if (industryGroups.length === 0) {
        return null
      }
      let results = industryGroups.map(x => x.industryGroup)
      results = _.uniq(results)
      return results
    },
    industryOptions: function () {
      if (!this.form.sector) {
        return null
      }
      if (!this.form.industrygroup) {
        return null
      }
      if (!this.industryGroupOptions) {
        return null
      }
      const industry = industries.filter(x => x.sector === this.form.sector && x.industryGroup === this.form.industrygroup)
      if (!Array.isArray(industry)) {
        return null
      }
      if (industry.length === 0) {
        return null
      }
      let results = industry.map(x => x.industry)
      results = _.uniq(results)
      return results
    },
    subIndustryOptions: function () {
      if (!this.form.sector) {
        return null
      }
      if (!this.form.industrygroup) {
        return null
      }
      if (!this.form.industry) {
        return null
      }
      if (!this.industryOptions) {
        return null
      }
      const industryGroups = industries.filter(x => x.sector === this.form.sector && x.industryGroup === this.form.industrygroup && x.industry === this.form.industry)
      if (!Array.isArray(industryGroups)) {
        return null
      }
      if (industryGroups.length === 0) {
        return null
      }
      let results = industryGroups.map(x => x.subIndustry)
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      let message = ''
      let valid = true
      if (this.form.function === '') {
        message = 'Select function.'
        valid = false
      }
      if (this.form.reason === '') {
        message = message + ' Provide reason.'
        valid = false
      }
      if (this.form.reason === 'other' && this.form.reasonother === '') {
        message = message + ' Provide other reason.'
        valid = false
      }
      if (this.form.reason === 'sales support' || this.form.reason === 'engagement support') {
        valid = false
        if (Number.isInteger(Number(this.form.size))) {
          if (Number(this.form.size) > 0) {
            if (this.form.sector) {
              valid = true
            } else {
              message = message + ' Select client sector.'
            }
          } else {
            message = message + ' Engagement size needs to be greater than zero.'
          }
        } else {
          message = message + ' Engagement size needs to be a whole number.'
        }
      }
      return { message: message, valid: valid }
    }
  },
  data () {
    return {
      config: {
        dates: {
          articles: {
            start: '2010-01-01',
            end: '2022-12-31'
          },
          events: {
            start: '2010-01-01',
            end: '2030-12-31'
          },
          publications: {
            start: '2010-01-01',
            end: '2022-12-31'
          }
        }
      },
      counter: 0,
      form: {
        function: '',
        industry: null,
        industrygroup: null,
        reason: '',
        reasonother: '',
        sector: null,
        size: null,
        subindustry: null
      },
      industry: '',
      industryGroup: '',
      initiativesFull: [],
      initiativesObligations: [],
      level1: structure.schema[0].id,
      level2: null,
      level3: null,
      loading: false,
      mandatory: [
        {
          key: 'mandatory',
          name: 'Mandatory'
        },
        {
          key: 'optional',
          name: 'Optional'
        },
        {
          key: 'complyexplain',
          name: 'Comply or explain'
        },
        {
          key: 'other',
          name: 'Other'
        }
      ],
      moment: moment,
      options: {
        assurance: { value: true, text: 'assurance' },
        country: { value: true, text: 'country and status' },
        dates: { value: true, text: 'dates' },
        descriptionInitiative: { value: true, text: 'description - initiative' },
        entities: { value: true, text: 'entities' },
        events: { value: true, text: 'events' },
        experts: { value: true, text: 'experts' },
        externalLinks: { value: true, text: 'external links' },
        headline: { value: true, text: 'headline' },
        insights: { value: true, text: 'insights' },
        publications: { value: true, text: 'publications' },
        regulators: { value: true, text: 'regulators' },
        reportingFrameworks: { value: true, text: 'reporting frameworks' },
        reportingFrequency: { value: true, text: 'reporting frequency' },
        reportingObligation: { value: true, text: 'reporting obligation' },
        reportType: { value: true, text: 'report type' },
        topics: { value: true, text: 'topics' },
        workflow: { value: true, text: 'workflow' }
      },
      reportFullOptions: ['split', 'tabular', 'together'],
      reportFullSelected: 'together',
      reportOptions: ['full', 'grouped', 'simple', 'obligations'],
      reportReady: false,
      reportSelected: null,
      sector: '',
      showControls: false,
      showReportingForm: true,
      subIndustry: '',
      workflow: workflow
    }
  },
  created: async function () {
    document.title = "Initiatives Reporting"
    this.workflow = workflow
    await this.$nextTick()
    if (this.initiatives.length > 0) {
      window.localStorage.setItem('frmCloudInitiatives', JSON.stringify(this.initiatives))
    } else {
      if (window.localStorage.getItem('frmCloudInitiatives') !== null) {
        this.initiatives = JSON.parse(window.localStorage.getItem('frmCloudInitiatives'))
      }
    }
    const reportLogged = await this.$Amplify.API.get('cosmos', `/impact/reportlogstatus/${this.user.username}`)
    if (reportLogged.status) {
      this.showReportingForm = false
      this.showControls = true
    }
  },
  methods: {
    excel: function () {
      this.$logger.debug('loading started')
      this.$stat.log({ page: 'initiatives reporting', action: 'download initiatives Excel report' })
      const data = []
      const fields = [
        { field: 'id', name: 'initiative id' },
        { field: 'name', name: 'name' },
        { field: 'headline', name: 'headline' },
        { field: 'description', name: 'description' },
        { field: 'country', name: 'country' },
        { field: 'issued', name: 'issued' },
        { field: 'effective', name: 'effective' },
        { field: 'first_reporting', name: 'first reporting' },
        { field: 'entities', name: 'entities' },
        { field: 'impactexperts', name: 'experts' },
        { field: 'status', name: 'status' },
        { field: 'impacttopics', name: 'topics' },
        { field: 'regulators', name: 'regulators' },
        { field: 'report_type', name: 'report type' },
        { field: 'reportingframeworks', name: 'reporting frameworks' },
        { field: 'assurance', name: 'assurance required' },
        { field: 'comply_explain', name: 'comply or explain' },
        { field: 'mandatory', name: 'mandatory' },
        { field: 'optional', name: 'optional' },
        { field: 'workflow_created_at', name: 'workflow stage created at' },
        { field: 'workflow_created_by', name: 'workflow created by' },
        { field: 'workflow_id', name: 'workflow stage' },
        { field: 'created_at', name: 'created at' },
        { field: 'created_by', name: 'created by' },
        { field: 'updated_at', name: 'updated at' },
        { field: 'updated_by', name: 'updated by' }
      ]
      const headings = _.map(fields, field => {
        return { value: field.name, type: 'string' }
      })
      data.push([{ value: 'RISK DISCLAIMER' }])
      data.push([{ value: 'This data is for KPMG internal use only. Engagement Leaders are solely responsible for data provided to clients and they need to perform appropriate review and quality control before providing any data to clients.' }])
      data.push(headings)
      const dlSource = this.initiatives
      _.each(dlSource, s => {
        const row = _.map(fields, field => {
          if (field.field === 'assurance') {
            if (s.assurance) {
              return { value: s.assurance.name, type: 'string' }
            } else {
              return { value: 'undefined', type: 'string' }
            }
          }
          if (field.field === 'description') {
            if (s.description) {
              let dsc = striptags(s.description, [], ' ').replace(/\s\s+/g, ' ')
              return { value: dsc, type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'entities') {
            return { value: s.entities.map(x => x.name), type: 'string' }
          }
          if (field.field === 'impactexperts') {
            return { value: s.impactexperts.map(x => x.name), type: 'string' }
          }
          if (field.field === 'impacttopics') {
            return { value: s.impacttopics.map(x => x.name), type: 'string' }
          }
          if (field.field === 'regulators') {
            return { value: s.regulators.map(x => x.name), type: 'string' }
          }
          if (field.field === 'reportingframeworks') {
            return { value: s.reportingframeworks.map(x => x.name), type: 'string' }
          }
          if (field.field === 'workflow_id') {
            return { value: workflow.find(x => x.id === s.workflow_id).name, type: 'string' }
          }
          return { value: s[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'initiatives' + moment().toISOString(), sheet: { data: data } }
      zipcelx(config)
    },
    fetchFull: async function (initiative) {
      const params = {
        body: this.config
      }
      const response = await this.$Amplify.API.post('cosmos', `/impact/initiative/${initiative.id}`, params)
      this.initiativesFull.push(response)
      this.counter = this.counter + 1
    },
    fetchObligations: async function (initiative) {
      const response = await this.$Amplify.API.get('cosmos', `/impact/initiative/${initiative.id}`)
      this.initiativesObligations.push(response)
      this.counter = this.counter + 1
    },
    fullReport: async function () {
      try {
        this.counter = 0
        this.loading = true
        this.reportReady = false
        await async.eachSeries(this.initiatives, async.asyncify(this.fetchFull))
        console.log('done fetching')
        console.log(this.initiativesFull)
        this.loading = false
        this.reportReady = true
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    generateReport: function () {
      this.$stat.log({ page: 'initiatives reporting', action: 'download initiatives Word report', payload: { reportType: this.reportSelected } })
      const start = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
      <head>
        <meta charset='utf-8'>
        <title>Export HTML To Doc</title>
        <style>
          body {
            font-family: arial;
          }
          h1, h2, h3, h4, h5 {
            color: #00338D;
          }
          h4 {
            margin-top: 18px;
            margin-bottom: 6px;
          }
          h5 {
            font-size: 1em;
          }
          table {
            border: 1px solid #00338D;
            border-collapse: collapse;
            font-size: 8px;
          }
          td {
            border: 1px solid #00338D;
            border-collapse: collapse;
            padding: 5px;
            vertical-align: top;
          }
          .mb-2 {
            margin-bottom: 6px;
          }
          .section {
            color: white;
            background-color: #00338D;
          }
        </style>
      </head>
      <body>
      <h1 style="color: red; margin-top: 50px; margin-bottom: 50px;">************</h1>
      <h1 style="color: red">Risk disclaimer</h1>
      <strong style="color: red">This data is for KPMG internal use only. Engagement Leaders are solely responsible for data provided to clients and they need to perform appropriate review and quality control before providing any data to clients.</strong>
      <h1 style="color: red; margin-top: 50px; margin-bottom: 50px;">************</h1>
      `
      const end = `
        <h1 style="color: red; margin-top: 50px; margin-bottom: 50px;">************</h1>
        <h1 style="color: red">Risk disclaimer</h1>
        <strong style="color: red">This data is for KPMG internal use only. Engagement Leaders are solely responsible for data provided to clients and they need to perform appropriate review and quality control before providing any data to clients.</strong>
        <h1 style="color: red; margin-top: 50px; margin-bottom: 50px;">************</h1>
        </body></html>
      `
      const html = start + document.getElementById('exportElement').innerHTML + end
      // const blob = new Blob(['\ufeff', html], { type: 'application/msword' })
      const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)
      const filename = 'initiatives' + moment().toISOString() + '.doc'
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
    },
    onSubmit: async function (event) {
      event.preventDefault()
      try {
        this.form.created_by = this.user.username
        this.form.filters = {
          assurance: this.assuranceSelected,
          country: this.countrySelected,
          dataset: this.datasetSelected,
          entity: this.entitySelected,
          experts: this.expertsSelected,
          mandatory: this.mandatorySelected,
          reportingfrequency: this.reportingfrequencySelected,
          regulator: this.regulatorSelected,
          reportingframework: this.reportingframeworkSelected,
          status: this.statusSelected,
          topic: this.topicSelected,
          workflow: this.workflowSelected
        }
        const params = {
          body: this.form
        }
        await this.$Amplify.API.put('cosmos', '/standard/impactreportingmi', params)
        this.showReportingForm = false
        this.showControls = true
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    reportObligations: async function () {
      try {
        this.counter = 0
        this.loading = true
        this.reportReady = false
        await async.eachSeries(this.initiatives, async.asyncify(this.fetchObligations))
        console.log('done fetching')
        console.log(this.initiativesObligations)
        this.loading = false
        this.reportReady = true
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    }
  },
  watch: {
    reportSelected: function () {
      if (this.reportSelected === 'full') {
        this.reportReady = false
      }
      if (this.reportSelected === 'grouped') {
        this.reportReady = true
      }
      if (this.reportSelected === 'obligations') {
        this.reportReady = false
        this.reportObligations()
      }
      if (this.reportSelected === 'simple') {
        this.reportReady = true
      }
    }
  }
}
</script>
<style>
  #exportElement > h1, h2, h3, h4 {
    color: #00338D;
  }
  #exportElement h4 {
    margin-top: 18px;
    margin-bottom: 6px;
  }
  #exportElement .section {
    color: white;
    background-color: #00338D;
  }
</style>
