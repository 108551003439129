<template>
<div>
  <h1 v-if="heading === 'h1'" style="margin-bottom: 20px; margin-left:0px; margin-right: 0px; font-size: 24px; color: #00338D">{{item.name}}</h1>
  <h3 v-if="heading === 'h3'" style="margin-bottom: 20px; margin-left:0px; margin-right: 0px; color: #00338D">{{item.name}}</h3>
  <div v-if="options.country.value"><p>{{item.country}} | {{item.impactstatus.name}}</p></div>
  <p v-if="options.headline.value">{{item.headline}}</p>
  <div v-if="options.descriptionInitiative.value" v-html="item.description"></div>
  <div v-if="options.workflow.value">
    <h4>Workflow</h4>
    <div>status: <strong>{{workflow.find(x => x.id === item.workflow_id).name}}</strong></div>
    <div v-if="item.workflow_created_by"><small>{{item.workflow_created_by}} at {{moment(item.workflow_created_at).format('YYYY-MM-DD HH:mm')}}</small></div>
  </div>
  <div v-if="item.impactexperts.length > 0 && options.experts.value">
    <h4 class="mt-5">Experts</h4>
    <div v-for="expert in item.impactexperts" :key="'expert'+expert.id">
      {{expert.name}}
    </div>
  </div>
  <div v-if="(item.issued || item.effective || item.first_reporting) && options.dates.value">
    <h4 class="mt-5">Dates</h4>
    <div v-if="item.issued">Issued: {{item.issued}}</div>
    <div v-if="item.effective">Effective: {{item.effective}}</div>
    <div v-if="item.first_reporting">First reporting: {{item.first_reporting}}</div>
  </div>
  <div v-if="item.reportingfrequency && options.reportingFrequency.value">
    <div v-if="item.reportingfrequency.id != 1">
      <h4 class="mt-5">Reporting frequency</h4>
      <div>{{item.reportingfrequency.name}}</div>
    </div>
  </div>
  <div v-if="item.report_type && options.reportType.value">
    <h4 class="mt-5">Report type</h4>
    <div>{{item.report_type}}</div>
  </div>
  <div v-if="item.reportingframeworks.length > 0 && options.reportingFrameworks.value">
    <h4 class="mt-5">Reporting frameworks</h4>
    <div v-for="row in item.reportingframeworks" :key="'entity'+row.id" class="mb-2">
      {{row.name}}
    </div>
  </div>
  <div v-if="item.assurance && options.assurance.value">
    <div v-if="item.assurance.id != 1">
      <h4 class="mt-5">Assurance</h4>
      <div>{{item.assurance.name}}</div>
    </div>
  </div>
  <div v-if="(item.mandatory || item.optional || item.comply_explain) && options.reportingObligation.value">
    <h4 class="mt-5">Reporting obligation</h4>
    <div>
      <span v-if="item.mandatory">mandatory | </span>
      <span v-if="item.optional">optional | </span>
      <span v-if="item.comply_explain">comply or explain</span>
    </div>
  </div>
  <div v-if="item.impacttopics.length > 0 && options.topics.value">
    <h4 class="mt-5">Topics</h4>
    <div v-for="topic in item.impacttopics" :key="'topic'+topic.id">
      {{topic.name}}
    </div>
  </div>
  <div v-if="item.regulators.length > 0 && options.regulators.value">
    <h4 class="mt-5">Regulators</h4>
    <div v-for="row in item.regulators" :key="'entity'+row.id" class="mb-2">
      <strong>{{row.name}}</strong><br />{{row.comments}}
    </div>
  </div>
  <div v-if="item.entities.length > 0 && options.entities.value">
    <h4 class="mt-5">Entities</h4>
    <div v-for="row in item.entities" :key="'entity'+row.id" class="mb-2">
      {{row.name}}
    </div>
  </div>
  <div v-if="item.impactinitiativeslinks.length > 0 && options.externalLinks.value">
    <h4 class="mt-5">External links</h4>
    <div v-for="link in item.impactinitiativeslinks" :key="'link'+link.id" class="mb-3">
      <div><a :href="link.url" target="_blank">{{link.name}}</a></div>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment'
import workflow from '@/workflows/initiative'

export default {
  name: 'InitiativeReport',
  props: {
    item: {
      type: Object,
      required: true
    },
    heading: {
      type: String,
      required: false,
      default: 'h1'
    },
    options: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      workflow: workflow
    }
  }
}
</script>

<style>
</style>
