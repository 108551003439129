export default [
  {
    "sector": "Energy",
    "industryGroup": "Energy",
    "industry": "Energy Equipment & Services",
    "subIndustry": "Oil & Gas Drilling"
  },
  {
    "sector": "Energy",
    "industryGroup": "Energy",
    "industry": "Energy Equipment & Services",
    "subIndustry": "Oil & Gas Equipment & Services"
  },
  {
    "sector": "Energy",
    "industryGroup": "Energy",
    "industry": "Oil, Gas & Consumable Fuels",
    "subIndustry": "Integrated Oil & Gas"
  },
  {
    "sector": "Energy",
    "industryGroup": "Energy",
    "industry": "Oil, Gas & Consumable Fuels",
    "subIndustry": "Oil & Gas Exploration & Production"
  },
  {
    "sector": "Energy",
    "industryGroup": "Energy",
    "industry": "Oil, Gas & Consumable Fuels",
    "subIndustry": "Oil & Gas Refining & Marketing"
  },
  {
    "sector": "Energy",
    "industryGroup": "Energy",
    "industry": "Oil, Gas & Consumable Fuels",
    "subIndustry": "Oil & Gas Storage & Transportation"
  },
  {
    "sector": "Energy",
    "industryGroup": "Energy",
    "industry": "Oil, Gas & Consumable Fuels",
    "subIndustry": "Coal & Consumable Fuels"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Chemicals",
    "subIndustry": "Commodity Chemicals"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Chemicals",
    "subIndustry": "Diversified Chemicals"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Chemicals",
    "subIndustry": "Fertilizers & Agricultural Chemicals"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Chemicals",
    "subIndustry": "Industrial Gases"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Chemicals",
    "subIndustry": "Specialty Chemicals"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Construction Materials",
    "subIndustry": "Construction Materials"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Containers & Packaging",
    "subIndustry": "Metal & Glass Containers"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Containers & Packaging",
    "subIndustry": "Paper Packaging"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Metals & Mining",
    "subIndustry": "Aluminum"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Metals & Mining",
    "subIndustry": "Diversified Metals & Mining"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Metals & Mining",
    "subIndustry": "Copper"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Metals & Mining",
    "subIndustry": "Gold"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Metals & Mining",
    "subIndustry": "Precious Metals & Minerals"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Metals & Mining",
    "subIndustry": "Silver"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Metals & Mining",
    "subIndustry": "Steel"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Paper & Forest Products",
    "subIndustry": "Forest Products"
  },
  {
    "sector": "Materials",
    "industryGroup": "Materials",
    "industry": "Paper & Forest Products",
    "subIndustry": "Paper Products"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Capital Goods",
    "industry": "Aerospace & Defense",
    "subIndustry": "Aerospace & Defense"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Capital Goods",
    "industry": "Building Products",
    "subIndustry": "Building Products"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Capital Goods",
    "industry": "Construction & Engineering",
    "subIndustry": "Construction & Engineering"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Capital Goods",
    "industry": "Electrical Equipment",
    "subIndustry": "Electrical Components & Equipment"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Capital Goods",
    "industry": "Electrical Equipment",
    "subIndustry": "Heavy Electrical Equipment"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Capital Goods",
    "industry": "Industrial Conglomerates",
    "subIndustry": "Industrial Conglomerates"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Capital Goods",
    "industry": "Machinery",
    "subIndustry": "Construction Machinery & Heavy Trucks"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Capital Goods",
    "industry": "Machinery",
    "subIndustry": "Agricultural & Farm Machinery"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Capital Goods",
    "industry": "Machinery",
    "subIndustry": "Industrial Machinery"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Capital Goods",
    "industry": "Trading Companies & Distributors",
    "subIndustry": "Trading Companies & Distributors"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Commercial & Professional Services",
    "industry": "Commercial Services & Supplies",
    "subIndustry": "Commercial Printing"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Commercial & Professional Services",
    "industry": "Commercial Services & Supplies",
    "subIndustry": "Environmental & Facilities Services"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Commercial & Professional Services",
    "industry": "Commercial Services & Supplies",
    "subIndustry": "Office Services & Supplies"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Commercial & Professional Services",
    "industry": "Commercial Services & Supplies",
    "subIndustry": "Diversified Support Services"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Commercial & Professional Services",
    "industry": "Commercial Services & Supplies",
    "subIndustry": "Security & Alarm Services"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Commercial & Professional Services",
    "industry": "Professional Services",
    "subIndustry": "Human Resource & Employment Services"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Commercial & Professional Services",
    "industry": "Professional Services",
    "subIndustry": "Research & Consulting Services"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Transportation",
    "industry": "Air Freight & Logistics",
    "subIndustry": "Air Freight & Logistics"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Transportation",
    "industry": "Airlines",
    "subIndustry": "Airlines"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Transportation",
    "industry": "Marine",
    "subIndustry": "Marine"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Transportation",
    "industry": "Road & Rail",
    "subIndustry": "Railroads"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Transportation",
    "industry": "Road & Rail",
    "subIndustry": "Trucking"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Transportation",
    "industry": "Transportation Infrastructure",
    "subIndustry": "Airport Services"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Transportation",
    "industry": "Transportation Infrastructure",
    "subIndustry": "Highways & Railtracks"
  },
  {
    "sector": "Industrials",
    "industryGroup": "Transportation",
    "industry": "Transportation Infrastructure",
    "subIndustry": "Marine Ports & Services"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Automobiles & Components",
    "industry": "Auto Components",
    "subIndustry": "Auto Parts & Equipment"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Automobiles & Components",
    "industry": "Auto Components",
    "subIndustry": "Tires & Rubber"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Automobiles & Components",
    "industry": "Automobiles",
    "subIndustry": "Automobile Manufacturers"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Automobiles & Components",
    "industry": "Automobiles",
    "subIndustry": "Motorcycle Manufacturers"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Durables & Apparel",
    "industry": "Household Durables",
    "subIndustry": "Consumer Electronics"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Durables & Apparel",
    "industry": "Household Durables",
    "subIndustry": "Home Furnishings"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Durables & Apparel",
    "industry": "Household Durables",
    "subIndustry": "Homebuilding"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Durables & Apparel",
    "industry": "Household Durables",
    "subIndustry": "Household Appliances"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Durables & Apparel",
    "industry": "Household Durables",
    "subIndustry": "Housewares & Specialties"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Durables & Apparel",
    "industry": "Leisure Products",
    "subIndustry": "Leisure Products"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Durables & Apparel",
    "industry": "Textiles, Apparel & Luxury Goods",
    "subIndustry": "Apparel, Accessories & Luxury Goods"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Durables & Apparel",
    "industry": "Textiles, Apparel & Luxury Goods",
    "subIndustry": "Footwear"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Durables & Apparel",
    "industry": "Textiles, Apparel & Luxury Goods",
    "subIndustry": "Textiles"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Services",
    "industry": "Hotels, Restaurants & Leisure",
    "subIndustry": "Casinos & Gaming"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Services",
    "industry": "Hotels, Restaurants & Leisure",
    "subIndustry": "Hotels, Resorts & Cruise Lines"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Services",
    "industry": "Hotels, Restaurants & Leisure",
    "subIndustry": "Leisure Facilities"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Services",
    "industry": "Hotels, Restaurants & Leisure",
    "subIndustry": "Restaurants"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Services",
    "industry": "Diversified Consumer Services",
    "subIndustry": "Education Services"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Consumer Services",
    "industry": "Diversified Consumer Services",
    "subIndustry": "Specialized Consumer Services"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Retailing",
    "industry": "Distributors",
    "subIndustry": "Distributors"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Retailing",
    "industry": "Internet & Direct Marketing Retail",
    "subIndustry": "Internet & Direct Marketing Retail"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Retailing",
    "industry": "Multiline Retail",
    "subIndustry": "Department Stores"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Retailing",
    "industry": "Multiline Retail",
    "subIndustry": "General Merchandise Stores"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Retailing",
    "industry": "Specialty Retail",
    "subIndustry": "Apparel Retail"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Retailing",
    "industry": "Specialty Retail",
    "subIndustry": "Computer & Electronics Retail"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Retailing",
    "industry": "Specialty Retail",
    "subIndustry": "Home Improvement Retail"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Retailing",
    "industry": "Specialty Retail",
    "subIndustry": "Specialty Stores"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Retailing",
    "industry": "Specialty Retail",
    "subIndustry": "Automotive Retail"
  },
  {
    "sector": "Consumer Discretionary",
    "industryGroup": "Retailing",
    "industry": "Specialty Retail",
    "subIndustry": "Homefurnishing Retail"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Food & Staples Retailing",
    "industry": "Food & Staples Retailing",
    "subIndustry": "Drug Retail"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Food & Staples Retailing",
    "industry": "Food & Staples Retailing",
    "subIndustry": "Food Distributors"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Food & Staples Retailing",
    "industry": "Food & Staples Retailing",
    "subIndustry": "Food Retail"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Food & Staples Retailing",
    "industry": "Food & Staples Retailing",
    "subIndustry": "Hypermarkets & Super Centers"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Food, Beverage & Tobacco",
    "industry": "Beverages",
    "subIndustry": "Brewers"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Food, Beverage & Tobacco",
    "industry": "Beverages",
    "subIndustry": "Distillers & Vintners"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Food, Beverage & Tobacco",
    "industry": "Beverages",
    "subIndustry": "Soft Drinks"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Food, Beverage & Tobacco",
    "industry": "Food Products",
    "subIndustry": "Agricultural Products"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Food, Beverage & Tobacco",
    "industry": "Food Products",
    "subIndustry": "Packaged Foods & Meats"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Food, Beverage & Tobacco",
    "industry": "Tobacco",
    "subIndustry": "Tobacco"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Household & Personal Products",
    "industry": "Household Products",
    "subIndustry": "Household Products"
  },
  {
    "sector": "Consumer Staples",
    "industryGroup": "Household & Personal Products",
    "industry": "Personal Products",
    "subIndustry": "Personal Products"
  },
  {
    "sector": "Health Care",
    "industryGroup": "Health Care Equipment & Services",
    "industry": "Health Care Equipment & Supplies",
    "subIndustry": "Health Care Equipment"
  },
  {
    "sector": "Health Care",
    "industryGroup": "Health Care Equipment & Services",
    "industry": "Health Care Equipment & Supplies",
    "subIndustry": "Health Care Supplies"
  },
  {
    "sector": "Health Care",
    "industryGroup": "Health Care Equipment & Services",
    "industry": "Health Care Providers & Services",
    "subIndustry": "Health Care Distributors"
  },
  {
    "sector": "Health Care",
    "industryGroup": "Health Care Equipment & Services",
    "industry": "Health Care Providers & Services",
    "subIndustry": "Health Care Services"
  },
  {
    "sector": "Health Care",
    "industryGroup": "Health Care Equipment & Services",
    "industry": "Health Care Providers & Services",
    "subIndustry": "Health Care Facilities"
  },
  {
    "sector": "Health Care",
    "industryGroup": "Health Care Equipment & Services",
    "industry": "Health Care Providers & Services",
    "subIndustry": "Managed Health Care"
  },
  {
    "sector": "Health Care",
    "industryGroup": "Health Care Equipment & Services",
    "industry": "Health Care Technology",
    "subIndustry": "Health Care Technology"
  },
  {
    "sector": "Health Care",
    "industryGroup": "Pharmaceuticals, Biotechnology & Life Sciences",
    "industry": "Biotechnology",
    "subIndustry": "Biotechnology"
  },
  {
    "sector": "Health Care",
    "industryGroup": "Pharmaceuticals, Biotechnology & Life Sciences",
    "industry": "Pharmaceuticals",
    "subIndustry": "Pharmaceuticals"
  },
  {
    "sector": "Health Care",
    "industryGroup": "Pharmaceuticals, Biotechnology & Life Sciences",
    "industry": "Life Sciences Tools & Services",
    "subIndustry": "Life Sciences Tools & Services"
  },
  {
    "sector": "Financials",
    "industryGroup": "Banks",
    "industry": "Banks",
    "subIndustry": "Diversified Banks"
  },
  {
    "sector": "Financials",
    "industryGroup": "Banks",
    "industry": "Banks",
    "subIndustry": "Regional Banks"
  },
  {
    "sector": "Financials",
    "industryGroup": "Banks",
    "industry": "Thrifts & Mortgage Finance",
    "subIndustry": "Thrifts & Mortgage Finance"
  },
  {
    "sector": "Financials",
    "industryGroup": "Diversified Financials",
    "industry": "Diversified Financial Services",
    "subIndustry": "Other Diversified Financial Services"
  },
  {
    "sector": "Financials",
    "industryGroup": "Diversified Financials",
    "industry": "Diversified Financial Services",
    "subIndustry": "Multi-Sector Holdings"
  },
  {
    "sector": "Financials",
    "industryGroup": "Diversified Financials",
    "industry": "Diversified Financial Services",
    "subIndustry": "Specialized Finance"
  },
  {
    "sector": "Financials",
    "industryGroup": "Diversified Financials",
    "industry": "Consumer Finance",
    "subIndustry": "Consumer Finance"
  },
  {
    "sector": "Financials",
    "industryGroup": "Diversified Financials",
    "industry": "Capital Markets",
    "subIndustry": "Asset Management & Custody Banks"
  },
  {
    "sector": "Financials",
    "industryGroup": "Diversified Financials",
    "industry": "Capital Markets",
    "subIndustry": "Investment Banking & Brokerage"
  },
  {
    "sector": "Financials",
    "industryGroup": "Diversified Financials",
    "industry": "Capital Markets",
    "subIndustry": "Diversified Capital Markets"
  },
  {
    "sector": "Financials",
    "industryGroup": "Diversified Financials",
    "industry": "Capital Markets",
    "subIndustry": "Financial Exchanges & Data"
  },
  {
    "sector": "Financials",
    "industryGroup": "Diversified Financials",
    "industry": "Mortgage Real Estate Investment Trusts (REITs)",
    "subIndustry": "Mortgage REITs"
  },
  {
    "sector": "Financials",
    "industryGroup": "Insurance",
    "industry": "Insurance",
    "subIndustry": "Insurance Brokers"
  },
  {
    "sector": "Financials",
    "industryGroup": "Insurance",
    "industry": "Insurance",
    "subIndustry": "Life & Health Insurance"
  },
  {
    "sector": "Financials",
    "industryGroup": "Insurance",
    "industry": "Insurance",
    "subIndustry": "Multi-line Insurance"
  },
  {
    "sector": "Financials",
    "industryGroup": "Insurance",
    "industry": "Insurance",
    "subIndustry": "Property & Casualty Insurance"
  },
  {
    "sector": "Financials",
    "industryGroup": "Insurance",
    "industry": "Insurance",
    "subIndustry": "Reinsurance"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Software & Services",
    "industry": "IT Services",
    "subIndustry": "IT Consulting & Other Services"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Software & Services",
    "industry": "IT Services",
    "subIndustry": "Data Processing & Outsourced Services"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Software & Services",
    "industry": "IT Services",
    "subIndustry": "Internet Services & Infrastructure"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Software & Services",
    "industry": "Software",
    "subIndustry": "Application Software"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Software & Services",
    "industry": "Software",
    "subIndustry": "Systems Software"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Technology Hardware & Equipment",
    "industry": "Communications Equipment",
    "subIndustry": "Communications Equipment"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Technology Hardware & Equipment",
    "industry": "Technology Hardware, Storage & Peripherals",
    "subIndustry": "Technology Hardware, Storage & Peripherals"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Technology Hardware & Equipment",
    "industry": "Electronic Equipment, Instruments & Components",
    "subIndustry": "Electronic Equipment & Instruments"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Technology Hardware & Equipment",
    "industry": "Electronic Equipment, Instruments & Components",
    "subIndustry": "Electronic Components"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Technology Hardware & Equipment",
    "industry": "Electronic Equipment, Instruments & Components",
    "subIndustry": "Electronic Manufacturing Services"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Technology Hardware & Equipment",
    "industry": "Electronic Equipment, Instruments & Components",
    "subIndustry": "Technology Distributors"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Semiconductors & Semiconductor Equipment",
    "industry": "Semiconductors & Semiconductor Equipment",
    "subIndustry": "Semiconductor Equipment"
  },
  {
    "sector": "Information Technology",
    "industryGroup": "Semiconductors & Semiconductor Equipment",
    "industry": "Semiconductors & Semiconductor Equipment",
    "subIndustry": "Semiconductors"
  },
  {
    "sector": "Communication Services",
    "industryGroup": "Telecommunication Services",
    "industry": "Diversified Telecommunication Services",
    "subIndustry": "Alternative Carriers"
  },
  {
    "sector": "Communication Services",
    "industryGroup": "Telecommunication Services",
    "industry": "Diversified Telecommunication Services",
    "subIndustry": "Integrated Telecommunication Services"
  },
  {
    "sector": "Communication Services",
    "industryGroup": "Telecommunication Services",
    "industry": "Wireless Telecommunication Services",
    "subIndustry": "Wireless Telecommunication Services"
  },
  {
    "sector": "Communication Services",
    "industryGroup": "Media & Entertainment",
    "industry": "Media",
    "subIndustry": "Advertising"
  },
  {
    "sector": "Communication Services",
    "industryGroup": "Media & Entertainment",
    "industry": "Media",
    "subIndustry": "Broadcasting"
  },
  {
    "sector": "Communication Services",
    "industryGroup": "Media & Entertainment",
    "industry": "Media",
    "subIndustry": "Cable & Satellite"
  },
  {
    "sector": "Communication Services",
    "industryGroup": "Media & Entertainment",
    "industry": "Media",
    "subIndustry": "Publishing"
  },
  {
    "sector": "Communication Services",
    "industryGroup": "Media & Entertainment",
    "industry": "Entertainment",
    "subIndustry": "Movies & Entertainment"
  },
  {
    "sector": "Communication Services",
    "industryGroup": "Media & Entertainment",
    "industry": "Entertainment",
    "subIndustry": "Interactive Home Entertainment"
  },
  {
    "sector": "Communication Services",
    "industryGroup": "Media & Entertainment",
    "industry": "Interactive Media & Services",
    "subIndustry": "Interactive Media & Services"
  },
  {
    "sector": "Utilities",
    "industryGroup": "Utilities",
    "industry": "Electric Utilities",
    "subIndustry": "Electric Utilities"
  },
  {
    "sector": "Utilities",
    "industryGroup": "Utilities",
    "industry": "Gas Utilities",
    "subIndustry": "Gas Utilities"
  },
  {
    "sector": "Utilities",
    "industryGroup": "Utilities",
    "industry": "Multi-Utilities",
    "subIndustry": "Multi-Utilities"
  },
  {
    "sector": "Utilities",
    "industryGroup": "Utilities",
    "industry": "Water Utilities",
    "subIndustry": "Water Utilities"
  },
  {
    "sector": "Utilities",
    "industryGroup": "Utilities",
    "industry": "Independent Power and Renewable Electricity Producers",
    "subIndustry": "Independent Power Producers & Energy Traders"
  },
  {
    "sector": "Utilities",
    "industryGroup": "Utilities",
    "industry": "Independent Power and Renewable Electricity Producers",
    "subIndustry": "Renewable Electricity"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Equity Real Estate Investment Trusts (REITs)",
    "subIndustry": "Diversified REITs"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Equity Real Estate Investment Trusts (REITs)",
    "subIndustry": "Industrial REITs"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Equity Real Estate Investment Trusts (REITs)",
    "subIndustry": "Hotel & Resort REITs"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Equity Real Estate Investment Trusts (REITs)",
    "subIndustry": "Office REITs"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Equity Real Estate Investment Trusts (REITs)",
    "subIndustry": "Health Care REITs"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Equity Real Estate Investment Trusts (REITs)",
    "subIndustry": "Residential REITs"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Equity Real Estate Investment Trusts (REITs)",
    "subIndustry": "Retail REITs"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Equity Real Estate Investment Trusts (REITs)",
    "subIndustry": "Specialized REITs"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Real Estate Management & Development",
    "subIndustry": "Diversified Real Estate Activities"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Real Estate Management & Development",
    "subIndustry": "Real Estate Operating Companies"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Real Estate Management & Development",
    "subIndustry": "Real Estate Development"
  },
  {
    "sector": "Real Estate",
    "industryGroup": "Real Estate",
    "industry": "Real Estate Management & Development",
    "subIndustry": "Real Estate Services"
  }
]
